@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    /*color-scheme: light dark;*/
    color-scheme: light dark;
}

.button {
}

.form-select-colors {
  @apply bg-white dark:bg-gray-800 border-gray-300 dark:border-gray-700 text-gray-900 dark:text-gray-100 focus:ring-blue-500 dark:focus:ring-slate-600 focus:border-slate-500 dark:focus:border-slate-600;
}

.form-input-colors {
  @apply bg-white dark:bg-slate-800 border-gray-300 dark:border-gray-700 focus:border-indigo-500 dark:focus:border-slate-500 focus:ring-indigo-500 dark:focus:ring-slate-500 text-black dark:text-white  dark:placeholder-gray-400;
}

.form-checkbox-colors {
  @apply border-gray-300 dark:border-gray-600 bg-white dark:bg-slate-800 hover:bg-white dark:hover:bg-slate-800 text-indigo-600 dark:text-slate-300 focus:ring-indigo-500 dark:focus:ring-slate-400;
}

/*input.no-spin-button::-webkit-outer-spin-button,
input.no-spin-button::-webkit-inner-spin-button {
  display: none;
}*/

.grecaptcha-badge { visibility: hidden; }

.pulse-lime {
    border-radius: 9999px;
    background-color: rgba(132, 204, 22, 1);
    box-shadow: 0 0 0 0 rgba(132, 204, 22, 1);
    animation: lime-pulse 3s infinite;
}

@keyframes lime-pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(132, 204, 22, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(132, 204, 22, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(132, 204, 22, 0);
  }
}


.pulse-red {
    border-radius: 9999px;
    background-color: rgba(239, 68, 68, 1);
    box-shadow: 0 0 0 0 rgba(239, 68, 68, 1);
    animation: red-pulse 3s infinite;
}

@keyframes red-pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(239, 68, 68, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(239, 68, 68, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(239, 68, 68, 0);
  }
}


.pulse-amber {
    border-radius: 9999px;
    background-color: rgba(245, 158, 11, 1);
    box-shadow: 0 0 0 0 rgba(245, 158, 11, 1);
    animation: amber-pulse 3s infinite;
}

@keyframes amber-pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(245, 158, 11, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(245, 158, 11, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(245, 158, 11, 0);
  }
}

.pulse-orange {
    border-radius: 9999px;
    background-color: rgba(249, 115, 22, 1);
    box-shadow: 0 0 0 0 rgba(249, 115, 22, 1);
    animation: orange-pulse 3s infinite;
}

@keyframes orange-pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(249, 115, 22, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(249, 115, 22, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(249, 115, 22, 0);
  }
}
